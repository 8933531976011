import { h, Component } from 'preact';
import { Router } from 'preact-router';

import Header from './header';
import MainMenu from './main-menu';
import AudioService from '../services/audio-service'
import { Context, setContextComponent } from '../services/context';
import { getPreferences } from '../services/storage';

// Code-splitting is automated for routes
import Profile from '../routes/profile';
import Recognizer from '../routes/recognizer';
import Recorder from '../routes/recorder';
import Splitter from '../routes/splitter';
import Validator from '../routes/validator';
import Words from '../routes/words';

export default class App extends Component {
	
	componentDidMount() {
		const { base_code, target_code } = getPreferences();
		this.state = {
            playlist_mode: 'run_playlist',
			main_menu_is_active: false,
            base_code: base_code || 'eng',
            target_code: target_code || 'nld',
		};
		const comp = this
		setTimeout(() => {
			comp.setState(comp.state);
		})
	
		setContextComponent(this);

		const getContentText = async () => {
			const content_text = await AudioService.getContentText();
			if (!content_text.eng) {
				console.error('could not get content text:', content_text);
			}
			this.setState({ content_text });
		}
		getContentText();
	}

    /** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
	};

	render() {
		return (
			<Context.Provider value={this.state}>
                <div id="app">
                    <Header />
                    <Router onChange={this.handleRoute}>
                        <Words path="/" />
                        <Words path="/words" />
                        <Validator path="/validator" />
                        <Recognizer path="/recognizer" />
                        <Recorder path="/recorder" />
                        <Splitter path="/splitter" />
                        <Profile path="/profile/" user="me" />
                        <Profile path="/profile/:user" />
                    </Router>
                    <MainMenu />
                </div>
            </Context.Provider>
		);
	}
}
