import { createContext } from 'preact';

export const Context = createContext();

let contextComponent = null;

export const setContextComponent = comp => {
    contextComponent = comp;
}

export const getContext = () => {
    if (!contextComponent) return {}
    return contextComponent.state
}

export const updateContext = new_context => {
    if (!contextComponent) return
    contextComponent.setState(new_context);
}
