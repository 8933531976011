
const root = window;

export const fullUrl = path => {
    return `${root.nld_config.domain}${path}`
}

export const get = async (url) => {
    const result = await fetch(fullUrl(url));
    return await result.json();
};

export const getArrayBuffer = async (url) => {
    const result = await fetch(fullUrl(url));
    return await result.arrayBuffer();
};

export const post = async (url, data={}) => {
    return await fetch(fullUrl(url), { 
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        },
    })
    .then(result => result.json())
};

export const put = async (url, data={}) => {
    return await fetch(fullUrl(url), { 
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        },
    })
    .then(result => result.json())
};
