import { h, Component } from 'preact';

import AudioService from '../../services/audio-service'
import { getContext, updateContext } from '../../services/context';

import style from './style.css';

export default class Header extends Component {

	state = {
        language_data: {},
    }

    async componentDidMount() {
        const component = this
        setTimeout(async () => {
            const language_data = await AudioService.getLanguages();
            component.setState({ language_data });
        });
    }

    showMenu = () => {
        const context = getContext();
        updateContext({ main_menu_is_active: !context.main_menu_is_active })
    }

    render(props, { language_data }) {
        const { base_code, target_code } = getContext();
        let base_language = language_data[base_code] && language_data[base_code].find(item => {
            return item.content_key === base_code;
        })
        base_language = base_language || {};

        let target_language = language_data[target_code] && language_data[target_code].find(item => {
            return item.content_key === target_code;
        })
        target_language = target_language || {};

        return (
            <header class={style.header}>
                <h1>{base_language.content_text}
                <img class="to-lang-img" src="/assets/icons/right-arrow.svg" />
                {target_language.content_text}</h1>
                <div>
                    <img class="menu-img" src="/assets/icons/menu.svg" onClick={this.showMenu} />
                </div>
            </header>
        );
    }
}
