import { h, Component } from 'preact';

import { getContext, updateContext } from '../services/context';

import Text from './text';

export default class MainMenu extends Component {

    state = {};

    showSettingsEditor = () => {
        updateContext({ main_menu_is_active: false, playlist_mode: 'edit_settings' })
    }

    showPlaylist = () => {
        updateContext({ main_menu_is_active: false, playlist_mode: 'run_playlist' })
    }

    showPlaylistEditor = () => {
        updateContext({ main_menu_is_active: false, playlist_mode: 'edit_playlist' })
    }

    render() {
        const context = getContext();

        const display_style = {}
        if (!context.main_menu_is_active) {
            display_style.display = 'none';
        }

        return (
            <div style={display_style} class="main-menu">
                <div class="clickable" onClick={this.showPlaylist} >
                    <Text id="view_playlist_action" />
                </div>
                <div class="clickable" onClick={this.showPlaylistEditor} >
                    <Text id="edit_playlist_action" />
                </div>
                <div class="clickable" onClick={this.showSettingsEditor} >
                    <Text id="settings_action" />
                </div>
            </div>
        );
    }
}
