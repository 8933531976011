
import { getContext } from './context';

const getValue = key => {
    const prefs = localStorage.getItem(key) || '{}';
    return JSON.parse(prefs);
}

const setValue = (key, value) => {
    let merged = getValue(key);
    merged = {
        ...merged,
        ...value,
    }
    localStorage.setItem(key, JSON.stringify(merged));
}

export const getPreferences = () =>  {
    const prefs = getValue('sprkn:prefs')
    return prefs;
}

export const setPreferences = prefs => {
    setValue('sprkn:prefs', prefs);
}

const getPlaylistKey = () => {
    const { base_code, target_code } = getContext();
    return `sprkn:${base_code}:${target_code}:playlist`;

}

export const getPlaylist = () =>  {
    return getValue(getPlaylistKey());
}

export const setPlaylist = playlist => {
    setValue(getPlaylistKey(), playlist);
}

