
import { get } from './fetch'
import { getContext } from './context';
import { getPlaylist as getStoragePlaylist } from './storage';

let api_data_key = null;
let api_data_cache = null;
let language_data_cache = null;
let text_data_cache = null;

const getDataIndex = (decoded_data, time) => {
    const { length, duration, sampleRate } = decoded_data;
    let index = Math.floor(time * sampleRate);
    if (index < 0) {
        index = 0;
    }
    if (index > length - 1) {
        index = length - 1;
    }
    return index;
}

const getApiData = async () => {
    const { base_code, target_code } = getContext();
    const test_key = `${base_code}:${target_code}`
    if (!api_data_cache || api_data_key !== test_key) {
        const result = await get(`/audio-api/audio-data/${base_code}/${target_code}`);
        if (!result || !result.length) {
            console.error('could not get audio data:', result);
            return [];
        }
        api_data_cache = result;
        api_data_key = test_key;
    }
    return api_data_cache;
}

export default {

    getCategories: async () => {
        return await getApiData();
    },

    getLanguages: async () => {
        if (!language_data_cache) {
            const result = await get(`/audio-api/audio-data/languages`);
            if (!result || !result.eng) {
                console.error('could not get language data:', result);
                return {};
            }
            language_data_cache = {};
            Object.keys(result).forEach(language_code => {
                language_data_cache[language_code] = [];
                Object.keys(result[language_code]).forEach(language_key => {
                    language_data_cache[language_code].push({
                        language_code: language_code,
                        content_key: language_key,
                        content_text: result[language_code][language_key],
                    })
                })
            })
            Object.keys(language_data_cache).forEach(language_code => {
                language_data_cache[language_code].sort((a, b) => a.content_text.localeCompare(b.content_text));
            })
        }
        return language_data_cache;
    },
	
    getContentText: async () => {
        if (!text_data_cache) {
            const result = await get(`/audio-api/audio-data/content-text`);
            if (!result || !result.eng) {
                console.error('could not get content data:', result);
                return {};
            }
            text_data_cache = result;
        }
        return text_data_cache;
    },
	
	getPlaylist: async () => {
        const { base_code, target_code } = getContext();

        const api_data = await getApiData();
        const current_playlist = getStoragePlaylist();
        let use_playlist = false;
        use_playlist = Object.keys(current_playlist).some(key => {
            return current_playlist[key];
        });
        const audio_data = api_data.reduce((acc, category) => {
            category.audio_clips.forEach(item => {
                if (!item[base_code] || !item[target_code]) {
                    return;
                }

                if (!use_playlist || current_playlist[category.category]) {
                    acc.push({
                        ...item,
                    });
                }
            });
            return acc;
        }, []);
        return audio_data;
    },

    subWaveform: (decoded_data, start_time, end_time) => {
        let start_index = getDataIndex(decoded_data, start_time);
        const end_index = getDataIndex(decoded_data, end_time);
        const copy_length = end_index - start_index + 1;
        const audio_data = Array.from({ length: copy_length });
        const channel_data = decoded_data.getChannelData(0);
        for (let j = 0; j < copy_length; j++) {
            audio_data[j] = channel_data[start_index];
            start_index++;
        }
        return audio_data;
    },

}
